
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify";
import { QuestEndpoints } from "@/graph/profile-quests.endpoints";

const useClaim = ({
  claimSuccess
}: {
  claimSuccess: string;
}) => {
  return useMutation(
    {
      mutationFn: ({
        missionId,
        userId,
        token
      }: {
        missionId: number;
        userId: number;
        token: string;
      }) => QuestEndpoints.claimReward({ userId, missionId, token }),
      onSuccess: () => {
        toast.success(claimSuccess);
      },
      onError: () => {
        toast.error('Error claiming the reward. Please try again later.');
      }
    }
  );
}

export default useClaim;