import { ApgNotification } from "@/context/notification.context";
import { cn } from "@apg.gg/core/lib/cn";
import { formatDistance } from 'date-fns';
import { useTranslations } from 'next-intl';
import NotificationIcon from "@apg.gg/icons/lib/NotificationIcon";

export type CustomPayload = {
  content: string;
}

const CustomNotification: React.FC<{ 
  notification: ApgNotification, 
  selectedLocale: Locale,
  payload: CustomPayload
}> = ({ 
  notification, 
  selectedLocale, 
  payload
}) => {
  const t = useTranslations('notifications');

  return (
    <>
      <div className="flex items-center justify-center w-8 h-8 bg-blue-300 rounded-full">
        <NotificationIcon className="text-xl object-cover" />
      </div>
      <div className="flex flex-1 flex-col gap-0">
        <span
          className={cn(
            "text-sm leading-4",
            notification?.read ? "text-gray-400" : "text-white"
          )}
        >
          {payload.content}
        </span>
        <span
          className={cn(
            "text-[10px] text-white-300"
          )}
        >
          {formatDistance(new Date(notification?.createdAt), new Date(), { addSuffix: true, locale: selectedLocale })}
        </span>
      </div>
    </>
  )
}

export default CustomNotification;