import { FC, useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import CardQuest from '../CardQuest';
import useAuthStore from '@/store/auth.store';
import { useQuery } from '@tanstack/react-query';
import { QuestEndpoints } from '@/graph/profile-quests.endpoints';
import Spinner from '../ui/Spinner';
import Image from 'next/image';
import { cn } from '@/lib/utils';
import CoinPixelIcon from '@apg.gg/icons/lib/CoinPixelIcon';
import delay from '@apg.gg/core/lib/delay';
import Drawer from '@apg.gg/core/lib/Drawer';

export interface QuestsDrawerProps {
  isOpen: boolean;
  locale: string;
  onClose: () => void;
}

const QuestsDrawer: React.FC<QuestsDrawerProps> = ({
  isOpen,
  locale,
  onClose
}) => {
  const t = useTranslations('Common');
  const { userId, userData, authToken } = useAuthStore();
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (isOpen) {
      delay(() => setShowDrawer(true));
    }
  }, [isOpen])

  const handleOnClose = () => {
    setShowDrawer(false);
    delay(() => onClose());
  }

  const { data: quests, isLoading, refetch } = useQuery({
    queryKey: ['apg-user-quests', userId],
    queryFn: () => QuestEndpoints.getUserMissions({ userId, token: authToken }),
  })

  return (
    <Drawer
      position="right"
      isOpen={showDrawer}
      onClose={handleOnClose}
      disableClickOutsideToClose={false}
      classNames={{
        wrapper: "w-72 md:w-80",
        content: "w-72 md:w-80"
      }}
      content={
        <div className="flex flex-col gap-4 w-full pt-4">
          <div className="flex gap-2 items-center border-b border-b-black-800 pb-4">
            <Image className="rounded-full w-10 h-10 object-cover" src={userData?.profileImage || ""} alt={userData?.username || "APG.gg"} width={40} height={40} />
            <div className="flex gap-1 items-center">
              <CoinPixelIcon />
              <span className="text-body-sm">
                {userData?.totalCoins}
              </span>
            </div>
          </div>

          <div className="scrollbar-small-content">
            <div className={cn(
              "overflow-y-auto h-[calc(100vh-136px)] space-y-4",
              quests && quests.length > 2 ? "pr-2" : "pr-0"
            )}>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {quests?.map((quest, index) => (
                    <CardQuest
                      key={`quest-${index}`}
                      {...quest}
                      translations={{
                        buttonLabel: t('quest-claim-label'),
                        coinsLabel: t('quest-coins-label'),
                        completedLabel: t('quest-completed-label'),
                        claimedLabel: t('quest-claimed-label'),
                        claimSuccess: t('quest-claim-success')
                      }}
                      onCompleteClaim={refetch}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default QuestsDrawer;
