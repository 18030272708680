"use client";

import React, { FC, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import styles from '@/styles/Home.module.css'
import Sidebar, { ItemProps, SubItemProps } from '@apg.gg/core/lib/Sidebar';
import classNames from 'classnames';
import Button from '@apg.gg/core/lib/Button';
import Dropdown from '@apg.gg/core/lib/Dropdown';
import DropdownItem from '@apg.gg/core/lib/DropdownItem';
import SidebarItem from '@apg.gg/core/lib/SidebarItem';
import { ApgUser } from '@/domain/account.interface';
import AccountDrawer from '@/components/SidebarDrawers/AccountDrawer';
import EditorDrawer from '@/components/SidebarDrawers/EditorDrawer';
import SearchDrawer from '@/components/SidebarDrawers/SearchDrawer';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '@/lib/utils/database.config';
import recentPagesMapper from '@/lib/utils/recentPagesMapper';
import useSidebarStore from '@/store/sidebar.store';
import { useRouter } from 'next-nprogress-bar';
import { useQuery } from '@tanstack/react-query';
import { QuestEndpoints } from '@/graph/profile-quests.endpoints';
import { toast } from 'react-toastify';
import QuestsDrawer from '@/components/SidebarDrawers/QuestsDrawer';
import SwordsIcon from '@apg.gg/icons/lib/SwordsIcon';
import useAuthStore from '@/store/auth.store';
import { MissionsEndpoints } from '@/graph/missions/endpoints';
import { useNotification } from '@/context/notification.context';
import NotificationCenter from '@/components/SidebarDrawers/NotificationsDrawer';
import { useMessage } from '@/context/messages.context';
import MailIcon from '@apg.gg/icons/lib/MailIcon';
import { navbarItems } from '../customMenus';
import { cn } from '@apg.gg/core/lib/cn';
import NotificationIcon from '@apg.gg/icons/lib/NotificationIcon';
import Navbar from '@apg.gg/core/lib/Navbar';

interface LayoutAppProps {
  children: React.ReactNode;
  locale: string;
  subItems?: SubItemProps[];
  userProfile?: ApgUser | null;
  authToken?: string;
  userData?: ApgUser | null;
  isLogged: boolean;
}

const LayoutApp: FC<LayoutAppProps> = ({ 
  children,
  subItems, 
  locale,
  userData,
  authToken
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const t = useTranslations("Common");
  const sidebarRef = useRef<HTMLDivElement>(null);

  const { items, setLocale } = useSidebarStore();
  const { authToken: token, userData: userLogged } = useAuthStore();
  
  const [user, setUser] = useState<ApgUser | null>(userData || userLogged || null);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isQuestsDrawerOpen, setIsQuestsDrawerOpen] = useState<boolean>(false);
  const [isNotificationsDrawerOpen, setNotificationsMenuOpen] = useState<boolean>(false);
  const [selectedDrawer, setSelectedDrawer] = useState<string>('' as any);
  const [recentPages, setRecentPages] = useState<ItemProps[]>([]);
  const { unseenCountInitializer, isInitialized, unseenCount } = useNotification();
  const { unseenCountListener, isInitialized: isMessageInitialized, unseenCount: messageUnseenCount } = useMessage();

  // Dropdowns states
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
  
  const [menuItems, setMenuItems] = useState<ItemProps[]>(items);
  const recentProfiles = useLiveQuery(() => db.recentProfiles.get("user:local:recentProfiles"));

  const { data: newMissions } = useQuery({
    queryKey: ["apg-user-new-missions", userData?.id],
    queryFn: async () => await QuestEndpoints.updateNewMissions({ userId: userData?.id || userLogged?.id, token: authToken || token }),
    staleTime: 1000 * 60 * 20,
    enabled: (!!userData?.id && !!authToken) || (!!userLogged?.id && !!token),
  })

  useQuery({
    queryKey: ['apg-register-login'],
    queryFn: async () => await MissionsEndpoints.updateLastLogin({ userId: userData?.id as number || userLogged?.id as number, token: authToken || token }),
    staleTime: 1000 * 60 * 20,
    enabled: (!!userData?.id && !!authToken) || (!!userLogged?.id && !!token),
  })

  const [redirect] = useState<string>(pathname.includes('/auth/') ? '/feed' : pathname);

  const mainClasses = pathname.includes('/auth') ? "flex flex-col items-center justify-center gradient-4" : "";

  const classNamesMain = cn(
    "px-4 w-full h-[calc(100vh-56px)] overflow-y-auto scroll-smooth", 
    mainClasses,
    pathname === `/${locale}` ? 'px-0' : ''
  );

  const profileItemMenu = {
    key: 'account-profile',
    name: t('sidebar.account'),
    icon: (
      <div className="rounded-full relative w-10 h-10 group">
        <Image 
          src={userData?.profileImage || ''} 
          className="rounded-full w-10 h-10 object-cover" 
          alt={userData?.name || 'APG Profile'}
          width={40}
          height={40}
        />
      </div>
    ),
    iconHoverColor: "white-300",
    bgColor: "blue-600",
    activeBgColor: "blue-600",
  }
  
  useEffect(() => {
    if (recentProfiles) {
      const menuRecentItems = recentPagesMapper(recentProfiles.profiles, locale);
      setRecentPages(menuRecentItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentProfiles]);

  useEffect(() => {
    setMenuItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    setLocale(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (newMissions && newMissions > 0) {
      toast.success(t('new-missions-available', { count: newMissions }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMissions]);

  useEffect(() => {
    setUser(userData || userLogged || null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userLogged])

  useEffect(() => {
    unseenCountInitializer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);
  
  useEffect(() => {
    unseenCountListener();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessageInitialized]);

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVh();
    window.addEventListener('resize', setVh);
    return () => window.removeEventListener('resize', setVh);
  }, []);
  
  return (
    <>
      <Sidebar 
        logo={<Image src="/assets/resources/logo.png" alt='APG.gg' width={40} height={40} />}
        menuItems={menuItems}
        recentPages={recentPages || []}
        activeItem={pathname.split(`/${locale}`)[1] || ''}
        isOpen={isSidebarOpen}
        onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
        searchText={t('navbar.search')}
        tabletCollapsedWidth="64px"
        onToggleDrawer={(value) => {
          setIsDrawerOpen(true)
          setSelectedDrawer(value)
        }}
        actions={
          <>
            {user ? (
              <>
                <SidebarItem 
                  key={'account-profile'} 
                  item={profileItemMenu}
                  isActive={false}
                  onClick={(key) => {
                    setIsDrawerOpen(true)
                    setSelectedDrawer(key)
                  }}
                />
              </>
            ) : null}
          </>
        }
        actionsMobile={
          <>
            {user ? (
              <div className="mb-4">
                <SidebarItem 
                  key={'account-profile'} 
                  item={profileItemMenu}
                  isActive={false}
                  onClick={(key) => {
                    setIsDrawerOpen(true)
                    setSelectedDrawer(key)
                  }}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-2 justify-center p-4">
                <Button className="mobile-login-btn" onClick={() => router.push(`/${locale}/auth/login?redirect=${redirect}`)} type="primary">
                  {t("navbar.login-btn")}
                </Button>
                <Button className="mobile-register-btn" onClick={() => router.push(`/${locale}/auth/sign-up?redirect=${redirect}`)} type="outline">
                  {t("navbar.register-btn")}
                </Button>
              </div>
            )}
          </>
        }
      >
        <main className={classNames(
          styles.main,
          "scrollbar-trigger"
        )}>
          <Navbar 
            logo="/assets/resources/logo.png" 
            onMenuToggle={() => setIsSidebarOpen(!isSidebarOpen)}
            actions={
              <>
                {user ? (
                  <div className="flex gap-2">
                    <Button
                      onClick={() => {
                        setIsQuestsDrawerOpen(!isQuestsDrawerOpen)
                      }}
                      type="outline"
                      className="w-8 h-8 !px-0 relative"
                      icon={<SwordsIcon />}
                    ></Button>
                    <Button
                      onClick={() => router.push(`/${locale}/messages`)}
                      type="outline"
                      className="w-8 h-8 !px-0 relative"
                      icon={<MailIcon />}
                    >
                      {messageUnseenCount > 0 ? (
                        <div className="flex absolute -top-1.5 -right-1 text-xs h-4 w-4 bg-blue-400 items-center justify-center whitespace-nowrap text-white rounded-full">
                          <span className="">{messageUnseenCount}</span>
                        </div>
                      ) : null}
                    </Button>
                    <Button
                      onClick={() => {
                        setNotificationsMenuOpen(!isNotificationsDrawerOpen)
                      }}
                      type="outline"
                      className="w-8 h-8 !px-0 relative"
                      icon={<NotificationIcon />}
                    >
                      {unseenCount > 0 ? (
                        <div className="flex absolute -top-1.5 -right-1 text-xs h-4 w-4 bg-blue-400 items-center justify-center whitespace-nowrap text-white rounded-full">
                          <span className="">{unseenCount}</span>
                        </div>
                      ) : null}
                    </Button>
                    <Dropdown 
                      placement="bottomRight"
                      trigger={["click"]}
                      visible={profileMenuOpen}
                      onVisibleChange={(visible) => setProfileMenuOpen(visible)}
                      overlayClassName='bg-black-900 shadow-md rounded p-2 z-50'
                      content={(
                        <div className="flex flex-col gap-1 w-40 bg-">
                          {navbarItems({
                            t,
                            router,
                            locale,
                            username: user.username
                          }).map((item, index) => (
                            <DropdownItem 
                              key={`${item.label}-${index}`} 
                              item={item} 
                              prefixCls='dropdown' 
                              handleItemClick={() => {
                                setProfileMenuOpen(false)
                                item.onClick?.()
                              }} 
                            />
                          ))}
                        </div>
                      )}
                    >
                      <div className="flex gap-2 items-center rounded-full border border-blue-400" role="button" onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
                        <Image className="rounded-full w-8 h-8 object-cover" src={user.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`} alt={user.username} width={100} height={100} />
                      </div>
                    </Dropdown>
                  </div>
                ) : (
                  <>
                    <Button className="navbar-login-btn" onClick={() => router.push(`/${locale}/auth/login?redirect=${redirect}`)} type="primary">
                      {t("navbar.login-btn")}
                    </Button>
                    <Button className="navbar-register-btn" onClick={() => router.push(`/${locale}/auth/sign-up?redirect=${redirect}`)} type="outline">
                      {t("navbar.register-btn")}
                    </Button>
                  </>
                )}
              </>
            }
          />
          <div className={classNamesMain} ref={sidebarRef}>
            {children}
          </div>
        </main>
      </Sidebar>

      {isDrawerOpen && selectedDrawer === "account-profile" && (
        <AccountDrawer 
          isOpen={isDrawerOpen}
          locale={locale}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}

      {isDrawerOpen && selectedDrawer === "editor" && (
        <EditorDrawer 
          isOpen={isDrawerOpen}
          locale={locale}
          onClose={() => setIsDrawerOpen(false)}
          profileMenu={subItems || []}
          userProfile={userData as ApgUser}
        />
      )}

      {isDrawerOpen && selectedDrawer === "search" && (
        <SearchDrawer 
          isOpen={isDrawerOpen}
          locale={locale}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}

      {isQuestsDrawerOpen && (
        <QuestsDrawer 
          isOpen={isQuestsDrawerOpen}
          locale={locale}
          onClose={() => setIsQuestsDrawerOpen(false)}
        />
      )}

      {isNotificationsDrawerOpen && (
        <NotificationCenter 
          isOpen={isNotificationsDrawerOpen}
          onClose={() => setNotificationsMenuOpen(false)}
        />
      )}
    </>
  )
}

export default LayoutApp;